import axios, { AxiosError } from 'axios'
import I18n from '../helpers/I18n'

// This file exists primarily to provide a reliable way to inject CSRF token verification into axios

export const csrfToken: () => string = () => {
  const tokenElement = document.querySelector('meta[name="csrf-token"]')
  const token = tokenElement && tokenElement.getAttribute('content')
  if (token) {
    return token
  } else {
    console.error(
      'No CSRF Token Element Could be found in the header metadata. Cannot provide the token for the http client.'
    )
    return ''
  }
}

export const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    common: {
      'X-CSRF-Token': csrfToken(),
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
  params: {
    locale: I18n?.locale || 'en',
  },
})

interface StandardRailsJSONError {
  readonly message?: string
}
/**
 * Invokes a handler providing the error message return by the vention backend
 * if such a message was provided and in the correct format
 * @param error An error returned via Axios
 * @param handler What to do with the resulting error message, if found
 */
export function handleStandardVentionError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: AxiosError<StandardRailsJSONError>,
  handler: (message: string) => void
): void {
  // Check that we are sure of this being an AxiosError.
  // Reason is that typescript cannot constraint or guarantee error types with promises.
  if (error.isAxiosError) {
    const response = error.response
    const responseData: unknown = response?.data
    if (responseData instanceof Object) {
      if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        const message: unknown = responseData['message']
        if (typeof message === 'string') {
          handler(message)
        }
      }
    } else {
      responseData
    }
  }
}

export function handleFileDownload(
  fileContent,
  type,
  customFileName,
  contentDispositionHeader?: string
): void {
  const blob = new Blob([fileContent], { type })
  const url = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url

  let fileName = customFileName

  if (!customFileName && contentDispositionHeader) {
    fileName = contentDispositionHeader.split('filename=')[1].split(';')[0].replace(/"/g, '')
  }

  link.setAttribute('download', fileName)

  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
}

// Intercepts every axios request response to handle 401 cases
function errorHandler(error: AxiosError) {
  if (
    error.response?.status === 401 &&
    error.response?.data?.service !== 'login' &&
    error.response?.data?.is_user_logged_in === false
  ) {
    window.location.href = '/users/sign_in?timedout'
  }

  error.message = error.response?.data?.message || error.message

  return Promise.reject(error)
}

// Intercepts every axios request response to handle 401 cases
httpClient.interceptors.response.use(undefined, errorHandler)

const httpClientCsrfFree = axios.create({
  headers: {
    'Content-Type': 'application/json',
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
})

httpClientCsrfFree.interceptors.response.use(undefined, errorHandler)

export const httpCsrfFreeClient = httpClientCsrfFree

export default httpClient
